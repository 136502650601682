import { useReadContract } from 'wagmi';
import abi from '@/shared/abi/ERC20.json';
import { TokenAmount } from '@/shared/utils';
import { assetConstants } from '@/shared/utils/chainflip';

const FLIP_CONTRACT_ADDRESS = process.env.NEXT_PUBLIC_FLIP_CONTRACT_ADDRESS as `0x${string}`;
const STATE_CHAIN_GATEWAY_ADDRESS = process.env.NEXT_PUBLIC_STATE_CHAIN_GATEWAY_ADDRESS;

export const useFlipContract = () => {
  const { data, isLoading } = useReadContract({
    address: FLIP_CONTRACT_ADDRESS,
    functionName: 'balanceOf',
    abi,
    args: [STATE_CHAIN_GATEWAY_ADDRESS],
    query: {
      select: (amount: unknown) => {
        if (!amount) return null;
        return new TokenAmount(amount as string, assetConstants.Flip.decimals);
      },
      retry: true,
      retryDelay: 100,
      refetchInterval: 120_000,
    },
  });

  return {
    totalFundedFlip: data,
    isLoading,
  };
};
